<div class="search-container" [class.expanded]="isSearchVisible">
  <div class="search-panel" [class.visible]="isSearchVisible">
    <div class="search-input-wrapper">
      <input 
        type="text" 
        class="search-input"
        [placeholder]="config.placeholder"
        (input)="onSearchInput($event)"
        (keyup.enter)="onSearchSubmit($event.target.value)">
      <button class="close-search" (click)="toggleSearch()">&times;</button>
    </div>

    <div class="search-bar-results" *ngIf="hasQuery">
      <!-- Estado de carga -->
      <div class="search-state loading" *ngIf="isLoading">
        <i class="fas fa-circle-notch fa-spin"></i>
        <span>Buscando...</span>
      </div>

      <!-- Estado sin resultados -->
      <div class="search-state no-results" *ngIf="!isLoading && searchResultGroups.length === 0">
        <i class="fas fa-search"></i>
        <span>No se encontraron resultados</span>
      </div>

      <!-- Grupos de resultados -->
      <div class="result-groups" *ngFor="let group of searchResultGroups">
        <div class="group-header" (click)="toggleGroup(group)">
          <div class="group-title">
            <i class="fas" [ngClass]="{
              'fa-plane': group.type === 'airport',
              'fa-helicopter': group.type === 'machine',
              'fa-map-marked-alt': group.type === 'special',
              'fa-map-marker-alt': group.type === 'geographic'
            }"></i>
            <span>{{group.title}} ({{group.results.length}})</span>
          </div>
          <i class="fas" [ngClass]="{'fa-chevron-down': group.isExpanded, 'fa-chevron-right': !group.isExpanded}"></i>
        </div>

        <!-- Lista de resultados del grupo -->
        <div class="group-results" *ngIf="group.isExpanded">
          <div class="result-item"
               *ngFor="let result of group.results"
               (click)="onResultClick(result)">
            
            <!-- Máquinas -->
            <ng-container *ngIf="result.type === 'machine'">
              <div class="result-icon"
                   [style.background-color]="'#' + result.color">
                <i class="icon if2rt-{{result.icon}}"></i>
              </div>
              <div class="result-content">
                <div class="result-title">
                  <span class="callsign">{{result.title}}</span>
                  <span class="fleet-name">{{result.data.fleetName}}</span>
                  <span class="unit-name">{{result.data?.subunit || 'No asignada'}}</span>
                </div>
                <div class="location-info">
                  <i class="fas fa-map-marker-alt"></i>
                  <span>{{result.data?.location || 'Sin ubicación'}}</span>
                </div>
              </div>
            </ng-container>

            <!-- Aeropuertos -->
            <ng-container *ngIf="result.type === 'airport'">
              <div class="result-icon">
                <i class="fas fa-plane"></i>
              </div>
              <div class="result-content">
                <div class="result-title">
                  <span class="callsign">{{result.title}}</span>
                </div>
                <div class="location-info">
                  <span class="airport-code" *ngIf="result.data.code">{{result.data.code}}</span>
                  <i class="fas fa-map-marker-alt"></i>
                  <span>{{result.data.address}}</span>
                </div>
              </div>
            </ng-container>

            <!-- Ubicaciones Especiales -->
            <ng-container *ngIf="result.type === 'special'">
              <div class="result-icon">
                <img [src]="'assets/SpecialLocationIcons/' + result.icon" alt="icon">
              </div>
              <div class="result-content">
                <div class="result-title">
                  <span class="callsign">{{result.title}}</span>
                </div>
                <div class="location-info">
                  <i class="fas fa-map-marker-alt"></i>
                  <span>{{result.subtitle}}</span>
                </div>
              </div>
            </ng-container>

            <!-- Ubicaciones Geográficas -->
            <ng-container *ngIf="result.type === 'geographic'">
              <div class="result-icon">
                <i [class]="result.icon"></i>
              </div>
              <div class="result-content">
                <div class="result-title">
                  <span class="callsign">{{result.title}}</span>
                </div>
                <div class="location-info">
                  <span>{{result.subtitle.replace(result.title + ',', '')}}</span>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="search-toggle" (click)="toggleSearch()">
    <i class="fas fa-search"></i>
  </button>
</div> 