import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";

// Services
import { SharedService } from "./services/shared.service";
import { DOCUMENT } from "@angular/common";
import { environment } from "../environments/environment";
import { ModalMessage, TypeMsm } from "./models/Interfaces/modalMessage";

// component ok
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent implements OnInit {

  // public modalLoaderState: boolean = false;
  // public modalMessageState: boolean = false;
  // public dataMsm: ModalMessage;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    // this.listenerEvents();
    // Carga inicial de Iconos
    this.loadIconsApp();
  }
  
  // Función de Carga Inicial de Iconos
  private loadIconsApp() {
    let urlIconsStyle = environment.iconsUrl;
    this.document
      .getElementById("iconsApp")
      .setAttribute("href", urlIconsStyle);
  }

  /*------------------------------Inicio de Funciones Propias--------------------------*/

  // private changeStateModalLoader(data: boolean) {
  //   Promise.resolve(null).then(() => (this.modalLoaderState = data));
  // }

  // private changeStateModalMessage(data: ModalMessage) {
  //   this.dataMsm = data;
  //   Promise.resolve(null).then(() => (this.modalMessageState = true));
  //   if (data.typeMsm != TypeMsm.confirm)
  //     setTimeout(() => (this.modalMessageState = false), 6500);
  // }

  // public closeModal() {
  //   Promise.resolve(null).then(() => (this.modalMessageState = false));
  // }

  // private listenerEvents() {
  //   SharedService.changeStateModalLoaderEmitter.subscribe((data) =>
  //     this.changeStateModalLoader(data)
  //   );
  //   SharedService.changeStateModalMessageEmitter.subscribe((data) =>
  //     this.changeStateModalMessage(data)
  //   );
  //   SharedService.closeModalMessageEmitter.subscribe((data) =>
  //     this.closeModal()
  //   );
  // }
  /*------------------------------Fin de Funciones Propias--------------------------*/
}
