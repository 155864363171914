
import { LocationType } from "../Interfaces/location-type";
import { Region } from "./region";
import { Unit } from "./unit";
import { UseHistory } from "./useHistory";


export class SpecialLocation {

	/*1*/ 	public id: number;
    /*2*/ 	public namelocation: string;
	/*3*/   public laitude: string;
	/*4*/   public longitude: string;
	/*5*/   public regionid: number;
	/*6*/   public locationtypeid: number;
	/*7*/   public commandid: number;
    /*8*/   public fixunitid: number;
	/*4*/   public url: string;
    
	public unit: Unit
	public UseHistory?: Array<UseHistory>;
	public locationtype?: LocationType;
	public region?: Region

	constructor(a ?:any) {
		this.id = a ? a.id : null;
		this.namelocation = a ? a.namelocation : null;
		this.laitude = a ? a.laitude : null;
		this.longitude = a ? a.longitude : null;
		this.regionid = a ? a.regionID : null;
		this.locationtypeid = a ? a.locationtypeid : null;
		this.commandid = a ? a.commandID : null;
		this.fixunitid = a ? a.fixUnitID : null;
		this.url = a ? a.url : null;

		this.unit = (a && a.unit) ? new Unit(a.unit) : null;
		this.locationtype = (a && a.locationtype) 
		this.region = (a && a.region) ? new Region(a.region) : null;
		this.UseHistory = (a && a.UseHistory) ? new Array<UseHistory>(a.UseHistory) : null;
	}

}
