import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { SearchService } from '../../../services/search.service';
import { SearchResult, SearchConfig, SearchResultGroup } from '../../../models/Interfaces/search.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.sass']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() config: SearchConfig;
  @Output() resultSelected = new EventEmitter<SearchResult>();

  searchResultGroups: SearchResultGroup[] = [];
  isSearchVisible = false;
  isLoading = false;
  hasQuery = false;
  private subscription: Subscription;

  constructor(private searchService: SearchService) {}

  ngOnInit() {
    this.subscription = this.searchService.results$.subscribe(
      (groups: SearchResultGroup[]) => {
        if (this.hasQuery) {
          this.searchResultGroups = groups.map(group => ({
            ...group,
            isExpanded: true // Por defecto expandidos
          }));
          this.isLoading = false;
        } else {
          this.searchResultGroups = [];
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSearchInput(event: Event): void {
    const query = (event.target as HTMLInputElement).value.trim();
    // console.log('🔍 Input de búsqueda:', query);
    
    this.hasQuery = query.length > 0;
    this.isSearchVisible = true;
    
    if (!query) {
      this.searchResultGroups = [];
      this.hasQuery = false;
      this.isLoading = false;
      return;
    }

    if (query.length >= this.config.minLength) {
      // console.log('🚀 Iniciando búsqueda con query:', query);
      this.isLoading = true;
      this.searchService.search(query, this.config);
    } else {
      this.searchResultGroups = [];
    }
  }

  onSearchSubmit(query: string): void {
    if (!query) {
      return;
    }

    this.isSearchVisible = true;
    this.hasQuery = true;
    
    if (query.length >= this.config.minLength) {
      this.isLoading = true;
      this.searchService.search(query, this.config);
    }
  }

  onResultClick(result: SearchResult): void {
    this.resultSelected.emit(result);
    this.clearSearch();
  }

  toggleSearch(): void {
    this.isSearchVisible = !this.isSearchVisible;
    if (!this.isSearchVisible) {
      this.clearSearch();
    }
  }

  toggleGroup(group: SearchResultGroup): void {
    group.isExpanded = !group.isExpanded;
  }

  private clearSearch(): void {
    this.searchResultGroups = [];
    this.hasQuery = false;
    this.isLoading = false;
    this.isSearchVisible = false;
    const input = document.querySelector('.search-input') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }
}