import { Countries } from "./countries";

export class Region {

	/*1*/ 	public id: number;
	/*2*/ 	public nameRegion: string;
	/*3*/ 	public countryID: number;

	public ISO_code?: string
	public country?: Countries;

	constructor(a?: any) {
		this.id = a ? a.id : null;
		this.countryID = a ? a.countryID : null;
		this.nameRegion = a ? a.nameRegion : null;
	}

}
