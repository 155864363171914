import { NgModule } from "@angular/core";
import { CommonModule, LowerCasePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';

// Pipes
import { UrlSaniter } from "../../pipes/url-saniter.pipe";
import { Time } from "../../pipes/time.pipe";
import { LowerCaseFirstCapital } from "../../pipes/lowerCaseFirstCapital.pipe";
import { HourPipe } from "src/app/pipes/hour.pipe";

// Componentes Propios
import { LoaderComponent } from "./loader/loader.component";
import { MessageComponent } from "./message/message.component";
import { CircularMenuComponent } from "./circular-menu/circular-menu.component";
import { LicenseComponent } from "./license/license.component";
import { NewResourceComponent } from "./new-resource/new-resource.component";
import { MultiselectComponent } from "./multiselect/multiselect.component";
import { ResumeComponent } from "./resume/resume.component";
import { HeaderModalComponent } from "src/app/components/shared/header-modal/header-modal.component";
import { BuildingInfoComponent } from "src/app/components/shared/building-info/building-info.component";
import { MachineInfoComponent } from "src/app/components/shared/machine-info/machine-info.component";
import { GreenCardMachineComponent } from "src/app/components/shared/machine-info/green-card-machine/green-card-machine.component";
import { BlueCardMachineComponent } from "src/app/components/shared/machine-info/blue-card-machine/blue-card-machine.component";
import { RedCardMachineComponent } from "src/app/components/shared/machine-info/red-card-machine/red-card-machine.component";
import { YellowCardMachineComponent } from "src/app/components/shared/machine-info/yellow-card-machine/yellow-card-machine.component";
import { FullInfoMachineComponent } from "src/app/components/shared/machine-info/full-info-machine/full-info-machine.component";
import { SideFilterComponent } from "./side-filter/side-filter.component";
import { ComponentCardComponent } from "./component-card/component-card.component";
import { MaintenanceComponent } from "../modals/manteinance/maintenance.component";
import { MaintenanceCmpComponent } from "../modals/manteinance-cmp/maintenance-cmp.component";
import { ProgressMaintHistoryMenuComponent } from "./progress-maint-history/progress-maint-history.component";
import { HistoryMachineInfoComponent } from "src/app/components/shared/history-machine-info/history-machine-info.component";
import { PernoctaComponent } from "../modals/pernocta/pernocta.component";
import { HttpClientModule } from "@angular/common/http";
import { InfoServiceComponent } from "./info-service/info-service.component";
import { CardHearderComponent } from './card-hearder/card-hearder.component';
import { HourUseHistoryComponent } from './hour-use-history/hour-use-history.component';
import { ControlFlightHoursComponent } from './card-components/control-flight-hours/control-flight-hours.component';
import { NewControlFlightHoursComponent } from './card-components/new-control-flight-hours/new-control-flight-hours.component';
import { WorkOrderComponent } from './card-components/work-order/work-order.component';
import { CompletionPercentageComponent } from './card-components/completion-percentage/completion-percentage.component';
import { StatusAnnotationsComponent } from './card-components/status-annotations/status-annotations.component';
import { NewStatusAnnotationsComponent } from './card-components/new-status-annotations/new-status-annotations.component';
import { CompletionPercentageAverageComponent } from "./card-components/completion-percentage-average/completion-percentage-average.component";
import { NewCardHearderComponent } from "./new-card-hearder/new-card-hearder.component";
import { NewMachineInfoComponent } from "./new-machine-info/new-machine-info.component";
import { NewYellowCardMachineComponent } from "./new-machine-info/new-yellow-card-machine/new-yellow-card-machine.component";
import { NewGreenCardMachineComponent } from "./new-machine-info/new-green-card-machine/new-green-card-machine.component";
import { NewRedCardMachineComponent } from "./new-machine-info/new-red-card-machine/new-red-card-machine.component";
import { NewBlueCardMachineComponent } from "./new-machine-info/new-blue-card-machine/new-blue-card-machine.component";
import { NewMaintenanceComponent } from "../modals/new-manteinance/new-maintenance.component";
import { SearchBarComponent } from './search/search-bar.component';

let listComponents = [
  LoaderComponent,
  MessageComponent,
  CircularMenuComponent,
  LicenseComponent,
  NewResourceComponent,
  MultiselectComponent,
  ResumeComponent,
  HeaderModalComponent,
  BuildingInfoComponent,
  MachineInfoComponent,
  GreenCardMachineComponent,
  BlueCardMachineComponent,
  RedCardMachineComponent,
  YellowCardMachineComponent,
  FullInfoMachineComponent,
  SideFilterComponent,
  ComponentCardComponent,
  MaintenanceComponent,
  MaintenanceCmpComponent,
  ProgressMaintHistoryMenuComponent,
  HistoryMachineInfoComponent,
  PernoctaComponent,
  InfoServiceComponent,
  CardHearderComponent,
  HourUseHistoryComponent,
  CompletionPercentageAverageComponent,

  NewCardHearderComponent,
  NewMachineInfoComponent,
  NewYellowCardMachineComponent,
  NewGreenCardMachineComponent,
  NewRedCardMachineComponent,
  NewBlueCardMachineComponent,
  NewMaintenanceComponent,
  SearchBarComponent
];

@NgModule({
  imports: [CommonModule, FormsModule, 
    HttpClientModule, MatCheckboxModule],
  declarations: [listComponents, UrlSaniter, Time, LowerCaseFirstCapital, HourPipe, ControlFlightHoursComponent,NewControlFlightHoursComponent, WorkOrderComponent, CompletionPercentageComponent, StatusAnnotationsComponent,NewStatusAnnotationsComponent],
  exports: [listComponents, Time, CommonModule, FormsModule, LowerCaseFirstCapital, HourPipe, MatCheckboxModule],
})
export class SharedModule {}
