import { catchError, map, switchMap, timeout } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import { SharedService } from "./shared.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

// Environments
import { environment } from "./../../environments/environment";
import { MensajesService } from "./mensajes.service";
import { from, Observable, throwError } from "rxjs";
import { AuthUserService } from "./auth-users/auth-users-service.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public urlHttp: string = environment.baseUrl
  private controller = new AbortController()
  private inProcess: boolean = false

  constructor(
    private http: HttpClient,
    private _sharedService: SharedService,
    private _alert: MensajesService,
    private injector: Injector // Agrega Injector en lugar de AuthUserService
  ) { }

  getHeader(): HttpHeaders {
    const token = this._sharedService.getToken()

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    if (token) {
      headers = new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": token,
      });
    }

    return headers;
  }

  // httpGet(url: string): any {
  //   let token_status = this.isTokenExpired(this._sharedService.getToken())
  //   console.log(token_status)
  //   if(!token_status){
  //     // await this.processRefleshToken()
  //   }
  //   let result = this.http.get(this.urlHttp + url, {
  //     headers: this.getHeader()
  //   }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
  //     return throwError(err);
  //   }))
  //   return result
  // }

  httpGet(url: string): Observable<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());
    
    if (!token_status && !this.inProcess) {
      this.inProcess = true
      // Si el token ha expirado, primero lo refrescamos
      return from(this.processRefleshToken()).pipe( // Convertimos la Promise en un Observable
        switchMap(() => this.http.get(this.urlHttp + url, {
          headers: this.getHeader()
        }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err))))
      );
    }

    return this.http.get(this.urlHttp + url, {
      headers: this.getHeader()
    }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err)));
  }

  // httpGetToken(url: string, timeOut: number): any {
  //   let result = this.http.get(this.urlHttp + url, {
  //     headers: this.getHeader()
  //   }).pipe(timeout(timeOut != undefined ? timeOut : this._alert._timeOut), catchError((err: any) => {
  //     return throwError(err);
  //   }))
  //   return result
  // }

  httpGetToken(url: string, timeOut?: number): Observable<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    if (!token_status && !this.inProcess) {
      this.inProcess = true
      // Si el token ha expirado, primero lo refrescamos
      return from(this.processRefleshToken()).pipe( // Espera a que el token se actualice
        switchMap(() =>
          this.http.get(this.urlHttp + url, {
            headers: this.getHeader()
          }).pipe(
            timeout(timeOut ?? this._alert._timeOut), // Usa el timeout proporcionado o el valor por defecto
            catchError(err => throwError(() => err))
          )
        )
      );
    }

    return this.http.get(this.urlHttp + url, {
      headers: this.getHeader()
    }).pipe(
      timeout(timeOut ?? this._alert._timeOut),
      catchError(err => throwError(() => err))
    );
  }

  // httpPost(url: string, data: any): any {
  //   let result = this.http.post(this.urlHttp + url, data, {
  //     headers: this.getHeader(),
  //   }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
  //     return throwError(err);
  //   }))
  //   return result
  // }

  httpPost(url: string, data: any): Observable<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    if (!token_status && !this.inProcess) {
      this.inProcess = true
      // Si el token ha expirado, primero lo refrescamos
      return from(this.processRefleshToken()).pipe(
        switchMap(() => this.http.post(this.urlHttp + url, data, {
          headers: this.getHeader(),
        }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err))))
      );
    }

    return this.http.post(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err)));
  }

  // httpDelete(url: string): any {
  //   let result = this.http.delete(this.urlHttp + url, {
  //     headers: this.getHeader()
  //   }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
  //     return throwError(err);
  //   }))
  //   return result
  // }

  httpDelete(url: string): Observable<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    if (!token_status && !this.inProcess) {
      this.inProcess = true
      // Si el token ha expirado, primero lo refrescamos
      return from(this.processRefleshToken()).pipe(
        switchMap(() => this.http.delete(this.urlHttp + url, {
          headers: this.getHeader()
        }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err))))
      );
    }

    return this.http.delete(this.urlHttp + url, {
      headers: this.getHeader()
    }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err)));
  }

  // httpPut(url: string, data: any): any {
  //   let result = this.http.put(this.urlHttp + url, data, {
  //     headers: this.getHeader(),
  //   }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
  //     return throwError(err);
  //   }))
  //   return result
  // }

  httpPut(url: string, data: any): Observable<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    if (!token_status && !this.inProcess) {
      this.inProcess = true
      // Si el token ha expirado, primero lo refrescamos
      return from(this.processRefleshToken()).pipe(
        switchMap(() => this.http.put(this.urlHttp + url, data, {
          headers: this.getHeader(),
        }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err))))
      );
    }

    return this.http.put(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err)));
  }

  // httpPatch(url: string, data: any): any {
  //   let result = this.http.patch(this.urlHttp + url, data, {
  //     headers: this.getHeader(),
  //   }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
  //     return throwError(err);
  //   }))
  //   return result
  // }

  httpPatch(url: string, data: any): Observable<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    if (!token_status && !this.inProcess) {
      this.inProcess = true
      // Si el token ha expirado, primero lo refrescamos
      return from(this.processRefleshToken()).pipe(
        switchMap(() => this.http.patch(this.urlHttp + url, data, {
          headers: this.getHeader(),
        }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err))))
      );
    }

    return this.http.patch(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(timeout(this._alert._timeOut), catchError(err => throwError(() => err)));
  }

  httpGetStorage(url: string): any {
    return this.http.get(url);
  }

  // sendFile(file: any, url: string) {
  //   const token = this._sharedService.getToken()

  //   const urlHttp = this.urlHttp
  //   return new Promise(function (resolve, reject) {
  //     const xhr = new XMLHttpRequest();
  //     const fd = new FormData();
  //     xhr.open("POST", urlHttp + url, true);
  //     // Agregar el encabezado de autorización
  //     xhr.setRequestHeader("Authorization", token);
  //     xhr.onreadystatechange = function () {
  //       if (xhr.readyState == 4 && xhr.status == 200)
  //         resolve(JSON.parse(xhr.responseText));
  //     };
  //     fd.append("file", file);
  //     xhr.send(fd);
  //   });
  // }

  sendFile(file: any, url: string): Promise<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    return new Promise((resolve, reject) => {
      if (!token_status && !this.inProcess) {
        this.inProcess = true
        // Si el token ha expirado, primero lo refrescamos
        this.processRefleshToken().then(() => {
          this._executeFileUpload(file, url, resolve, reject);
        }).catch(reject);
      } else {
        this._executeFileUpload(file, url, resolve, reject);
      }
    });
  }

  private _executeFileUpload(file: any, url: string, resolve: Function, reject: Function): void {
    const token = this._sharedService.getToken();
    const urlHttp = this.urlHttp;

    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", urlHttp + url, true);
    xhr.setRequestHeader("Authorization", token);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(xhr.responseText);
        }
      }
    };

    fd.append("file", file);
    xhr.send(fd);
  }

  // deleteFile(url: string) {
  //   const token = this._sharedService.getToken()

  //   const urlHttp = this.urlHttp
  //   return new Promise(function (resolve, reject) {
  //     const xhr = new XMLHttpRequest();
  //     const fd = new FormData();
  //     xhr.open("DELETE", urlHttp + url, true);
  //     xhr.setRequestHeader("Authorization", token);
  //     xhr.onreadystatechange = function () {
  //       if (xhr.readyState == 4 && xhr.status == 200)
  //         resolve(JSON.parse(xhr.responseText));
  //     };
  //     //fd.append("file", file);
  //     xhr.send(fd);
  //   });
  // }

  deleteFile(url: string): Promise<any> {
    let token_status = this.isTokenExpired(this._sharedService.getToken());

    return new Promise((resolve, reject) => {
      if (!token_status && !this.inProcess) {
        this.inProcess = true
        // Si el token ha expirado, primero lo refrescamos
        this.processRefleshToken().then(() => {
          this._executeFileDeletion(url, resolve, reject);
        }).catch(reject);
      } else {
        this._executeFileDeletion(url, resolve, reject);
      }
    });
  }

  private _executeFileDeletion(url: string, resolve: Function, reject: Function): void {
    const token = this._sharedService.getToken();
    const urlHttp = this.urlHttp;

    const xhr = new XMLHttpRequest();
    xhr.open("DELETE", urlHttp + url, true);
    xhr.setRequestHeader("Authorization", token);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(xhr.responseText);
        }
      }
    };

    xhr.send();
  }

  //#region ChatBot
  getHeaderChatBot(): HttpHeaders {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "ACTION_SECRET": "Nhk03CBnel"
    });

    return headers;
  }

  httpPostChatBot(data: any): any {
    return this.http.post("https://us-central1-oncobot.cloudfunctions.net/dev-medicalProcessesChatBot/ask", data, {
      headers: this.getHeaderChatBot(),
    });
  }
  //#endregion ChatBot

  cancelRequest() {
    this.controller.abort()
  }

  //#region Procedimientos de RefleshToken
  private async processRefleshToken() {
    return new Promise<boolean>((resolve) => {
      const now = Math.floor(Date.now() / 1000); // Tiempo en segundo
      const remainingSeconds = Number(this._sharedService.getTokenTtl()) - now; // Restante en segundos

      const hours = Math.floor(remainingSeconds / 3600);

      // Iniciar el proceso de refresco de token
      const _authUserService = this.injector.get(AuthUserService);
      _authUserService.refleshToken(this._sharedService.getToken(), (hours <= -1 ? (-1 * hours) : 1))
        .then(result => {
          if (result && result.ttl) { // Asegurarse de que el resultado tiene el TTL
            this._sharedService.setTokenTtl(result.ttl);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(error => {
          resolve(false);
        }).finally(()=>{
          this.inProcess = false
        })
    });
  }

  private isTokenExpired(token: string): boolean {
    // console.log(token)
    const now = Math.floor(Date.now() / 1000); // Tiempo en segundo
    const timeTtl = Number(this._sharedService.getTokenTtl())
    const remainingSeconds = timeTtl - now; // Restante en segundos
    
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingMinutes = Math.floor(remainingSeconds / 60); // Convertir a minutos

    // console.log(hours, remainingMinutes,remainingSeconds)
    return remainingMinutes <= 0 ? false :  true; // Retorna true si el token ha expirado
  }
  //#endregion Procedimientos de RefleshToken
}
