import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  constructor(private _httpService: HttpService) { }

  // Consulta de contador de controles
  refleshToken(token: string,hours: number) {
    return new Promise<any>((resolve) => {
      const url: string = `auth/refresh-token`
      this._httpService.httpPost(url, {
        token: token,
        hours: hours
      }).subscribe(data => {
        resolve(data)
      }, erro => {
        resolve({})
      })
    });
  }

}
