export const environment = {
  production: false,

  // pusherKey: 'a10e229a150dfd4ebfce',
  // openaiKey: "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",  // Para ChatGPT

  pusherKey: 'a10e229a150dfd4ebfce',
  openaiKey: "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",  // Para ChatGPT
  //iconsUrl: "https://storage.googleapis.com/f2rt-assets/f2rtnew/styles.css",
  iconsUrl: "assets/stylesicon.css", 
  
  baseUrl: "https://f2rt-avi.appspot.com/api/", // Base de datos Prductivo (Rama: Master)
  //baseUrl: "https://api-test-dot-f2rt-avi.ue.r.appspot.com/api/", // Base de datos Test (Rama: GitLab)
  //baseUrl: "https://api-stage-dot-f2rt-avi.ue.r.appspot.com/api/", // Base de datos Dev (Rama: stage - dev)
  // baseUrl: "http://localhost:3000/api/", // Base de datos Dev desde localhost
  
  googleMaps: {
    apiKey: 'AIzaSyAeti2mPpKGTfdO2Nb6H9-j2Mi9KCgEHd8',
    options: {
      region: 'CO', // Región por defecto
      language: 'es', // Idioma por defecto
      libraries: ['places'], // Bibliotecas necesarias
    }
  },
  openWeatherMap: {
    apiKey: 'e1748bb9d7cc7c91d5a9623d00bcbfef',
    baseUrl: 'https://tile.openweathermap.org/map'
  }
};
