export interface SearchConfig {
  types: ('machine' | 'airport' | 'special' | 'geographic')[];
  minLength?: number;
  debounceTime?: number;
  maxResults?: number;
  placeholder?: string;
  enableLiveSearch?: boolean;
}

export interface SearchResult {
  id: string;
  type: string;
  title: string;
  subtitle?: string;
  coordinates?: {
    lat: number;
    lng: number;
  };
  icon?: string;
  color?: string;
  data: any;
}

export interface SearchResultGroup {
  type: string;
  title: string;
  isExpanded?: boolean;
  results: SearchResult[];
}

export interface MachineSearchResult extends SearchResult {
  type: 'machine';
  color: string;
  icon: string;
  data: {
    callSign: string;
    fleetName: string;
    fleetIco: string;
    subunit?: string;
    location?: string;
    state: {
      name: string;
      color: string;
    };
  };
}

export interface LocationSearchResult extends SearchResult {
  type: 'airport' | 'special';
  data: {
    name: string;
    code?: string;
    OACI?: string;
    locationType: string;
    address?: string;
  };
}

export interface GeographicSearchResult extends SearchResult {
  type: 'geographic';
  data: {
    displayName: string;
    type: string;
    class?: string;
    osmId: number;
    osmType: string;
    importance: number;
    geojson?: {
      type: string;
      coordinates: number[][] | number[][][] | number[][][][];
    };
    boundingbox?: string[];
    address: {
      city?: string;
      town?: string;
      county?: string;
      state?: string;
      country?: string;
      postcode?: string;
      road?: string;
      suburb?: string;
      neighbourhood?: string;
      house_number?: string;
      amenity?: string;
      building?: string;
      office?: string;
      shop?: string;
      leisure?: string;
      tourism?: string;
      historic?: string;
      natural?: string;
      landuse?: string;
      military?: string;
      place?: string;
      railway?: string;
      aeroway?: string;
      boundary?: string;
    };
    extraTags?: {
      [key: string]: string;
    };
    nameDetails?: {
      name?: string;
      alt_name?: string;
      [key: string]: string | undefined;
    };
  };
} 