import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/services/shared.service';
import { AuthUserService } from 'src/app/services/auth-users/auth-users-service.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private _activeRequest = 0;
  private cancelSubject: Subject<any> = new Subject();

  constructor(
    private _ngxUiLoaderService: NgxUiLoaderService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this._activeRequest === 0) {
      this._ngxUiLoaderService.startBackground('do-background-things');
    }

    this._activeRequest++;

    // Pasar el Subject de cancelación a la solicitud
    return next.handle(request).pipe(
      finalize(() => this._stopLoader())
    );
  }

  // Método para cancelar la solicitud
  cancelRequest(): void {
    this.cancelSubject.next();
  }

  private _stopLoader() {
    this._activeRequest--;
    if (this._activeRequest === 0) {
      this._ngxUiLoaderService.stopBackground('do-background-things');
    }
  }
}

