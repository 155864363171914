import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of, combineLatest } from 'rxjs';
import { debounceTime, switchMap, map, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { MensajesService } from './mensajes.service';

// Importaciones de clases
import { Machine } from '../models/class/machine';
import { Airport } from '../models/class/airport';
import { SpecialLocation } from '../models/class/speciallocations';

// Importaciones de interfaces
import { 
  SearchResult, 
  SearchResultGroup,
  MachineSearchResult, 
  LocationSearchResult, 
  GeographicSearchResult,
  SearchConfig 
} from '../models/Interfaces/search.interface';
import { NominatimResponse } from '../models/Interfaces/nominatim-response.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private resultsSubject = new BehaviorSubject<SearchResultGroup[]>([]);
  public results$ = this.resultsSubject.asObservable();
  private searchSubject = new Subject<string>();
  private machines: Machine[] = [];

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private mensajesService: MensajesService
  ) {
    // Configurar el debounce para las búsquedas
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((query: string) => this.performSearch(query))
    ).subscribe(results => {
      this.resultsSubject.next(results);
    });
  }

  search(query: string, config: SearchConfig): void {
    this.searchSubject.next(query);
  }

  private performSearch(query: string): Observable<SearchResultGroup[]> {
    if (!query?.trim()) {
      return of([]);
    }

    return combineLatest([
      this.searchMachines(query).pipe(
        map(results => ({
          type: 'machine',
          title: 'Aeronaves',
          isExpanded: true,
          results
        }))
      ),
      this.searchLocations(query).pipe(
        map(results => {
          const airports = results.filter(r => r.type === 'airport');
          const special = results.filter(r => r.type === 'special');
          return [
            {
              type: 'airport',
              title: 'Aeródromos',
              isExpanded: true,
              results: airports
            },
            {
              type: 'special',
              title: 'Ubicaciones Especiales',
              isExpanded: true,
              results: special
            }
          ];
        })
      ),
      this.searchGeographic(query).pipe(
        map(results => ({
          type: 'geographic',
          title: 'Ubicaciones Geográficas',
          isExpanded: true,
          results: results.slice(0, 10)
        }))
      )
    ]).pipe(
      map(([machines, locations, geographic]) => {
        const groups = [machines, ...locations, geographic];
        return groups.filter(group => group.results.length > 0);
      })
    );
  }

  private searchMachines(query: string): Observable<MachineSearchResult[]> {
    try {
      if (!this.machines?.length) {
        return of([]);
      }

      const filteredMachines = this.machines.filter(machine => 
        machine.callSing?.toLowerCase().includes(query.toLowerCase()) ||
        machine.fleet?.fleetName?.toLowerCase().includes(query.toLowerCase())
      );
      
      
      return of(filteredMachines).pipe(
        map(machines => machines.map(machine => this.mapMachineToSearchResult(machine)))
      );
    } catch (error) {
      return of([]);
    }
  }

  private mapMachineToSearchResult(machine: Machine): MachineSearchResult {
    const location = machine.controlleruse?.[0]?.airport?.nameAirport || 
                    machine.controlleruse?.[0]?.departament?.regionName ||
                    'Sin ubicación';
    
    return {
      id: machine.id?.toString() || '',
      type: 'machine',
      title: machine.callSing || '',
      subtitle: machine.fleet?.fleetName || '',
      coordinates: {
        lat: Number(machine.controlleruse?.[0]?.airport?.latitude || 
                   machine.controlleruse?.[0]?.departament?.latitude || 0),
        lng: Number(machine.controlleruse?.[0]?.airport?.longitude || 
                   machine.controlleruse?.[0]?.departament?.longitude || 0)
      },
      icon: machine.fleet?.fleetIco || '',
      color: machine.typeState?.colorState || '',
      data: {
        callSign: machine.callSing || '',
        fleetName: machine.fleet?.fleetName || '',
        fleetIco: machine.fleet?.fleetIco || '',
        subunit: machine.controlleruse?.[0]?.subunit?.subUnitName || '',
        location: location,
        state: {
          name: machine.typeState?.typeStateName || '',
          color: machine.typeState?.colorState || ''
        }
      }
    };
  }

  private searchLocations(query: string): Observable<LocationSearchResult[]> {
    try {
      const airports = this.sharedService.getAirports() || [];
      const specialLocations = this.sharedService.getSpecialLocations() || [];

      return of([...airports, ...specialLocations]).pipe(
        map(locations => {
          const filtered = locations.filter(location => {
            if ('nameAirport' in location) {
              const airport = location as Airport;
              const searchQuery = query.toLowerCase();
              // Buscar en todos los campos relevantes
              return (
                airport.nameAirport?.toLowerCase().includes(searchQuery) ||
                airport.IATA?.toLowerCase().includes(searchQuery) ||
                airport.OACI?.toLowerCase().includes(searchQuery) ||
                airport.localization?.toLowerCase().includes(searchQuery)
              );
            } else {
              return location.namelocation?.toLowerCase().includes(query.toLowerCase());
            }
          });


          return filtered.map(location => this.mapLocationToSearchResult(location));
        })
      );
    } catch (error) {
      return of([]);
    }
  }

  private mapLocationToSearchResult(location: Airport | SpecialLocation): LocationSearchResult {
    const isAirport = 'nameAirport' in location;
    if (isAirport) {
      const airport = location as Airport;
      const codes = [airport.IATA, airport.OACI].filter(Boolean).join(' - ');
      return {
        id: airport.id?.toString() || '',
        type: 'airport',
        title: airport.nameAirport || '',
        subtitle: codes ? `${codes} | ${airport.localization || ''}` : airport.localization || '',
        coordinates: {
          lat: Number(airport.latitude || 0),
          lng: Number(airport.longitude || 0)
        },
        icon: 'plane',
        data: {
          name: airport.nameAirport || '',
          code: codes,
          locationType: 'Aeródromo',
          address: airport.localization || ''
        }
      };
    } else {
      return {
        id: location.id?.toString() || '',
        type: 'special',
        title: location.namelocation || '',
        subtitle: '',
        coordinates: {
          lat: Number(location.laitude || 0),
          lng: Number(location.longitude || 0)
        },
        icon: location.url || '',
        data: {
          name: location.namelocation || '',
          code: null,
          locationType: 'Ubicación Especial',
          address: location.namelocation || ''
        }
      };
    }
  }

  private searchGeographic(query: string): Observable<GeographicSearchResult[]> {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&addressdetails=1&extratags=1&namedetails=1&polygon_geojson=1&email=Soporte@F2RT.com`;
    
    return this.http.get<NominatimResponse[]>(url).pipe(
      map(results => 
        results.map(result => this.mapGeographicToSearchResult(result))
      ),
      catchError((error) => {
        this.mensajesService.showError('Error en la búsqueda geográfica');
        return of([]);
      })
    );
  }

  private mapGeographicToSearchResult(result: NominatimResponse): GeographicSearchResult {
    return {
      id: result.place_id.toString(),
      type: 'geographic',
      title: result.display_name.split(',')[0],
      subtitle: result.display_name,
      coordinates: {
        lat: Number(result.lat),
        lng: Number(result.lon)
      },
      icon: 'fas fa-map-marker-alt', // Ícono estándar para todas las ubicaciones geográficas
      data: {
        displayName: result.display_name,
        type: result.type,
        class: result.class,
        osmId: result.osm_id,
        osmType: result.osm_type,
        importance: result.importance,
        geojson: result.geojson,
        boundingbox: result.boundingbox,
        address: result.address,
        extraTags: result.extratags,
        nameDetails: result.namedetails
      }
    };
  }

  public updateMachines(machines: Machine[]) {
    this.machines = machines;
  }
} 